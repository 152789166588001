<template>
  <v-app>
    <v-main>
      <MainComponent v-if="isAuthenticated"/>
      <button v-if="!isAuthenticated" @click="login" class="login">Login</button>
      <footer>
        <div class="footer-button">
          <button v-if="isAuthenticated" @click="logout" class="logout">Logout</button>
        </div>
        <div class="footer-text">
          <p>
            Hosted with ❤️ on <a href="https://sartorius.atlassian.net/wiki/spaces/DT/pages/48693297/Marc+s+Container+Platform">Container Platform</a>
          </p>
        </div>
      </footer>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import MainComponent from './components/MainComponent.vue'
import { CacheLocation, UserAgentApplication } from 'msal';

export default defineComponent({
  name: 'App',

  components: {
    MainComponent,
  },

  data () {
    return {
      msalConfig: {
        auth: {
          clientId: process.env.VUE_APP_CLIENT_ID,
          authority: `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}`,
          redirectUri: process.env.VUE_APP_REDIRECT_URI,
        },
        cache: {
          cacheLocation: 'localStorage' as CacheLocation,
          storeAuthStateInCookie: true,
        },
      },
        msalInstance: null as UserAgentApplication | null,
        isAuthenticated: false,
    }
  },

  created() {
    this.msalInstance = new UserAgentApplication(this.msalConfig);
    this.isAuthenticated = this.msalInstance.getAccount() !== null;
  },

  methods: {
    login() {
      this.msalInstance?.loginPopup().then(() => {
        this.isAuthenticated = true;
      }).catch((error) => {
        console.error(error);
      });
    },
    logout() {
      this.msalInstance?.logout();
      this.isAuthenticated = false;
    }
  }
})
</script>

<style scoped>
.login {
  cursor: pointer;
  margin: 1em;
  padding: 1em 2em;
  background-color: #ffed00;
  font-size: 24px;
  font-weight: bold;
}

.logout {
  background-color: #ffed00;
  margin: 1em;
  padding: .25em .5em;
  font-weight: bold;
  color: black;
}

footer {
  padding-top: .25em;
  padding-bottom: .25em;
  background-color: black;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
}

.footer-button {
  flex-grow: 0;
}

.footer-text {
  flex-grow: 1;
  text-align: center;
}

a {
  font-weight: bold;
}

a:visited {
  color: inherit;
}
</style>